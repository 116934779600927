.row {
  margin: 0;
}

#root {
  width: 100%;
  height: 100vh;
}

body {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
}

.bg-green {
  background: #4caf50;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

/*--------- CARGA -----------*/
#carga {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #4caf50;
  z-index: 9999;
}

#carga .logo {
  width: 300px;
  margin: 40px auto;
}

#carga .logo img {
  width: 100%;
}

#carga .carga {
  padding: 40px;
  box-sizing: border-box;
}

.loader {
  border: 25px solid #f3f3f3;
  border-top: 25px solid #3498db;
  border-radius: 50%;
  width: 220px;
  height: 220px;
  animation: spin 1s linear infinite;
}
.logoDiv {
  width: 100%;
}
.logoLogin {
  width: 150px; 
  margin-left: 100px;
  margin-right: auto;
  padding: 10px; 
}
.title{
    text-align: center;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*------------------MENU------------------*/
#menu {
  margin-bottom: 20px;
}

.navbar {
  background-color: #4caf50;
}

.borderMenu {
  border: 1px solid #dee2e6 !important;
}

/* Cambia el color del icono a primario en hover */
.arrow-icon:hover {
  cursor: pointer;
}

/* Cambia el color del icono a secundario en hover */
.arrow-icon-secondary:hover {
  cursor: pointer;
}

/*--------------------LOGIN------------------------*/
/* Google Font Link */

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");

#login .container {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  max-width: 850px;
  width: 100%;
  background: #fff;
  padding: 40px 30px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  perspective: 2700px;
}

#login .container .cover {
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  width: 50%;
  z-index: 98;
  transition: all 1s ease;
  transform-origin: left;
  transform-style: preserve-3d;
}

#login .container #flip:checked ~ .cover {
  transform: rotateY(-180deg);
}

#login .container .cover .front,
#login .container .cover .back {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

#login .cover .back {
  transform: rotateY(180deg);
  backface-visibility: hidden;
}

#login .container .cover::before,
#login .container .cover::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background: #4caf50;
  opacity: 0.5;
  z-index: 12;
}

#login .container .cover::after {
  opacity: 0.3;
  transform: rotateY(180deg);
  backface-visibility: hidden;
}

#login .container .cover img {
  position: absolute;
  height: 20px;
  width: 20px;
  object-fit: cover;
  z-index: 10;
}

#login .container .cover .text img {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: 10;
}

#login .container .cover .text {
  position: absolute;
  z-index: 130;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#login .cover .text .text-1,
#login .cover .text .text-2 {
  font-size: 26px;
  font-weight: 600;
  color: #fff;
  text-align: center;
}

#login .cover .text .text-2 {
  font-size: 15px;
  font-weight: 500;
}

#login .container .forms {
  height: 100%;
  width: 100%;
  background: #fff;
}

#login .container .form-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#login .form-content .login-form,
#login .form-content .signup-form {
  width: calc(100% / 2 - 25px);
}

#login .forms .form-content .title {
  position: relative;
  font-size: 24px;
  font-weight: 500;
  color: #333;
}

#login .forms .form-content .title:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 3px;
  width: 25px;
  background: #4caf50;
}

#login .forms .signup-form .title:before {
  width: 20px;
}

#login .forms .form-content .input-boxes {
  margin-top: 30px;
}

#login .forms .form-content .input-box {
  display: flex;
  align-items: center;
  height: 50px;
  width: 100%;
  margin: 10px 0;
  position: relative;
}

#login .form-content .input-box input {
  height: 100%;
  width: 100%;
  outline: none;
  border: none;
  padding: 0 30px;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

#login .form-content .input-box input:focus,
#login .form-content .input-box input:valid {
  border-color: #4caf50;
}

#login .form-content .input-box i {
  position: absolute;
  color: #4caf50;
  font-size: 17px;
}

#login .forms .form-content .text {
  font-size: 14px;
  font-weight: 500;
  color: #333;
}

#login .forms .form-content .text a {
  text-decoration: none;
}

#login .forms .form-content .text a:hover {
  text-decoration: underline;
}

#login .forms .form-content .button {
  color: #fff;
  margin-top: 40px;
}

#login .forms .form-content .button input {
  color: #fff;
  background: #4caf50;
  border-radius: 6px;
  padding: 0;
  cursor: pointer;
  transition: all 0.4s ease;
}

#login .forms .form-content .button input:hover {
  background: #2f8032;
}

#login .forms .form-content label {
  color: #2f8032;
  cursor: pointer;
}

#login .forms .form-content label:hover {
  text-decoration: underline;
}

#login .forms .form-content .login-text,
#login .forms .form-content .sign-up-text {
  text-align: center;
  margin-top: 25px;
}

#login .container #flip {
  display: none;
}

/*-----------------------Home------------------------*/

.pointer:hover {
  cursor: pointer;
}

.Link {
  text-decoration: none;
  color: black;
}

.Link:hover {
  text-decoration: none;
  color: #4caf50;
}

/*------------------------------ALERTA MAPA---------------------*/
.inactive {
  opacity: 0.5;
}

.btn-outline-success.my-2:hover {
  color: white;
}

.map {
  height: 500px;
  width: 100%;
}

.leaflet-container {
  height: 100%;
  width: 100%;
  pointer-events: auto;
}

/*-------------------------------MEDIA---------------------------------*/

@media (max-width: 730px) {
  #login .container .cover {
    display: none;
  }

  #login .form-content .login-form,
  #login .form-content .signup-form {
    width: 100%;
  }

  #login .form-content .signup-form {
    display: none;
  }

  #login .container #flip:checked ~ .forms .signup-form {
    display: block;
  }

  #login .container #flip:checked ~ .forms .login-form {
    display: none;
  }
}

@media (max-width: 1136px) {
  #nav {
    width: 150px;
  }
}

@media (max-width: 899px) {
  #nav {
    height: 60px;
    width: 29%;
  }

  .borderMenu {
    border: none !important;
  }

  #nav .container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  #nav .container > * {
    flex-basis: 100%;
    text-align: center;
  }

  #nav .container > :first-child {
    order: -1;
  }

  #nav button,
  #nav a {
    flex-basis: calc(33.33% - 10px);
    margin: 5px;
  }
}
